<template>
  <div class="container">
    <div v-if="client">
      <div class="notesView">
        <div class="row mb-3">
          <div class="col-auto my-auto">
            <h5 class="mb-0 client-tab-heading">Notes</h5>
          </div>
          <div class="col text-end">
            <div class="dropleft">
              <button
                class="btn btn-outline-dark btn-sm dropdown-toggle"
                type="button"
                id="sortByDropDown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Sort by
              </button>
              <div class="dropdown-menu" aria-labelledby="sortByDropDown">
                <a class="dropdown-item" @click="sortNotesBy('oldest-newest')"
                  >Oldest to Newest</a
                >
                <a class="dropdown-item" @click="sortNotesBy('newset-oldest')"
                  >Newest to Oldest</a
                >
              </div>
            </div>
          </div>
        </div>

        <hr class="my-3" />

        <busy :visible="loading" />
        <div v-if="!loading">
          <div class="row mb-3 align-items-end">
            <div class="col-12 my-auto">
              <textarea
                rows="4"
                class="form-control"
                v-model="newNote"
                placeholder="Add a note..."
                v-tooltip:auto="'The client is unable to see notes added here'"
                autofocus
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-auto ms-auto text-end">
              <button
                class="btn btn-primary"
                :class="compact ? 'btn-sm' : ''"
                @click="addNewNote"
                :disabled="newNote == null || newNote == ''"
              >
                <i class="far fa-save me-1"></i>
                Save Note
              </button>
            </div>
          </div>
          <div class="row mt-3" v-if="notes.length > 0">
            <div class="col">
              <table
                class="table table-hover"
                :class="compact ? 'table-sm' : ''"
              >
                <thead>
                  <tr>
                    <th scope="col">Note</th>
                    <th scope="col" class="text-nowrap" v-if="!compact">
                      Creator
                    </th>
                    <th scope="col" class="text-nowrap" v-if="!compact">
                      Assignee
                    </th>
                    <th scope="col">Added</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="n in notes" :key="n.id" class="cursor-pointer">
                    <td v-if="editNoteId !== n.id" @click="expandNote(n)">
                      <span v-if="!n.expanded">
                        {{ n.body | truncate }}
                        <span v-if="n.body.length > 256">... </span
                        ><span
                          class="text-primary dashed-underline cursor-pointer"
                          v-if="n.body.length > 256"
                        >
                          show more
                        </span>
                      </span>
                      <span v-if="n.expanded">
                        {{ n.body }}
                        <span
                          class="text-primary dashed-underline cursor-pointer"
                          v-if="n.body.length > 256"
                        >
                          show less
                        </span>
                      </span>
                    </td>
                    <td v-if="editNoteId === n.id">
                      <textarea
                        rows="4"
                        class="form-control"
                        v-model="n.body"
                        autofocus
                      ></textarea>
                    </td>

                    <td v-if="!compact">
                      <img
                        :src="getColleagueProfilePicUrl(n.user_id)"
                        :alt="getColleagueName(n.user_id)"
                        v-tooltip:auto="getColleagueName(n.user_id)"
                        class="rounded-circle"
                        width="40"
                        height="40"
                      />
                    </td>

                    <td v-if="!compact">
                      <assign-team-member
                        :user="user"
                        :colleagues="colleagues"
                        :model="n"
                        @assigned="assignToUser"
                      ></assign-team-member>
                    </td>

                    <td class="text-nowrap">{{ n.created_at | formatDate }}</td>
                    <td class="text-nowrap">
                      <button
                        v-if="editNoteId !== n.id"
                        class="btn btn-sm btn-outline-dark mx-1"
                        @click="editNote(n)"
                      >
                        <i class="far fa-edit"></i>
                      </button>
                      <button
                        v-if="editNoteId !== n.id"
                        class="btn btn-sm btn-outline-danger mx-1"
                        @click="deleteNote(n.id)"
                      >
                        <i class="far fa-trash"></i>
                      </button>
                      <button
                        v-if="editNoteId === n.id"
                        class="btn btn-sm btn-outline-dark mx-1"
                        @click="editNoteId = null"
                      >
                        <i class="far fa-times"></i>
                      </button>
                      <button
                        v-if="editNoteId === n.id"
                        class="btn btn-sm btn-primary mx-1"
                        @click="saveNote(n)"
                      >
                        <i class="far fa-save"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row text-center" v-if="notes.length == 0">
            <div class="col">
              <h5>There are no notes for this client</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import AssignTeamMember from "../../global/AssignTeamMember";

export default {
  props: ["compact", "clientObj"],
  data() {
    return {
      client: null,
      loadingNotes: true,
      loadingColleagues: true,
      notes: [],
      note: null,
      newNote: "",
      tag: "",
      tags: [],
      editNoteId: null,
      colleagues: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    loading() {
      return this.loadingColleagues || this.loadingNotes;
    },
  },
  methods: {
    sortNotesBy(sort) {
      if (sort == "oldest-newest") {
        this.notes.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "newset-oldest") {
        this.notes.sort(
          (b, a) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
    },
    fetchColleagues() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/practitioners/fetch-clinic-users")
        .then(({ data }) => {
          this.colleagues = data;
          this.loadingColleagues = false;
        });
    },
    fetchClientNotes() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/notes"
        )
        .then(({ data }) => {
          this.notes = data;
          this.loadingNotes = false;
        });
    },
    assignToUser(data) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/notes/" +
            data.modelId +
            "/assign-user",
          {
            assignee_id: data.assignee.id,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchClientNotes();
        });
    },
    closeNote(note) {
      this.note = null;
    },
    addNewNote() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/add-note",
          {
            body: this.newNote,
            tags: this.tags,
          }
        )
        .then(({ data }) => {
          this.newNote = "";
          this.tags = [];
          this.tag = "";
          this.notes.unshift(data.note);
          this.fetchClientNotes();
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("refreshProfile");
        });
    },
    saveNote(note) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/update-note",
          {
            note_id: note.id,
            body: note.body,
          }
        )
        .then(({ data }) => {
          this.editNoteId = null;
          this.fetchClientNotes();
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("refreshProfile");
        });
    },
    editNote(note) {
      this.editNoteId = note.id;
    },
    deleteNote(note) {
      var result = confirm(
        "Are you sure you wish to delete this note, this action cannot be undone?"
      );
      if (result) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/delete-note/" +
              note
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchClientNotes();
          });
      }
    },
    expandNote(note) {
      note.expanded = note.expanded ? false : true;
      this.forceUpdateNote(note);
    },
    forceUpdateNote(note) {
      //workaround as "expanded" is note actually a note property and changing it doesnt trigger vue update
      note.body = note.body + " ";
      note.body = note.body.substring(0, note.body.length - 1);
    },
    getColleague(id) {
      if (id === this.user.id) {
        return this.user;
      }

      return this.colleagues.find((c) => c.id === id);
    },
    getColleagueName(id) {
      const colleague = this.getColleague(id);

      return colleague ? colleague.name : "";
    },
    getColleagueProfilePicUrl(id) {
      const colleague = this.getColleague(id);

      return colleague ? colleague.profile_pic_url : "";
    },
    handleExit() {
      if (this.editNoteId) {
        console.log("Saving note");
        this.saveNote(this.note);
      }
      if (this.newNote) {
        console.log("Adding note");
        this.addNewNote();
      }
    },
    async fetchClientData() {

      if(this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  beforeDestroy() {
    console.log("Closing notes");
    this.handleExit();
  },
  async mounted() {
    this.$stash.clientInView = this.clientObj ? this.clientObj.id : this.$route.params.id;
    await this.fetchClientData();
    this.fetchClientNotes();
    this.fetchColleagues();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("D/M/Y");
    },
    truncate(str) {
      if (str.length > 256) {
        str = str.substring(0, 256);
      }
      return str;
    },
  },
  components: {
    VueTagsInput,
    AssignTeamMember,
  },
};
</script>

<style>
</style>
