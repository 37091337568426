<template>
  <div>
    <div class="team-assign dropdown cursor-pointer">
      <img
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        v-if="model.assignee_id"
        :src="model.assignee.profile_pic_url"
        @click="openAssigneeMenu(model)"
        class="rounded-circle dropdown-toggle"
        :alt="model.assignee.name"
        v-tooltip:auto="model.assignee.name"
        width="40"
        height="40"
      />
      <img
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        v-else
        :src="user.profile_pic_url"
        @click="openAssigneeMenu(model)"
        class="rounded-circle dropdown-toggle"
        :alt="user.name"
        v-tooltip:auto="user.name"
        width="40"
        height="40"
      />

      <div class="dropdown-menu">
        <div
          class="dropdown-item border-bottom border-light pb-2"
          v-for="u in colleagues"
          :key="u.id"
          @click="assignToUser(model.id, u)"
        >
          <div class="row">
            <div class="col-3 my-auto">
              <img
                :src="u.profile_pic_url"
                class="rounded-circle cursor-pointer"
                width="40"
                height="40"
                :alt="u.name"
              />
            </div>
            <div class="col-9 my-auto">
              <h6 class="mb-0">
                {{ u.name }}
                <br />
                <small>{{ u.email }}</small>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["model", "user", "colleagues"],
  data() {
    return {
    };
  },
  methods: {
    openAssigneeMenu(model) {
      this.showMenu = model;
    },
    fetchColleagues() {
      if (!this.colleagues) {
        this.$axios
            .get(process.env.VUE_APP_API_URL + "/practitioners/fetch-clinic-users")
            .then(({ data }) => {
              this.colleagues = data;
            });
      }
    },
    assignToUser(modelId, user) {
      var data = {
        modelId: modelId,
        assignee: user,
      };

      this.$emit("assigned", data);
    },
  },
  mounted() {
    this.fetchColleagues();
  },
};
</script>

<style>
.team-assign .dropdown-menu {
  max-height: 450px;
  width: 350px;
  overflow-y: auto;
}
</style>
